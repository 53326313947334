<div class="modal-content1">
  <div class="modal-body">
    <h5 class="primary-message" md-dialog-title>{{primaryMessage}}</h5>
  </div>
  <div class="modal-footer">
    <div class="form-row w-100">
      <div class="col-md-auto col-auto">
        <button class="btn btn-success btn-sm m-right-10" (click)="clickHandler(true)"><i class="fas fa-check"></i>
          {{'YES' | translate}}</button>
        <button class="btn btn-danger btn-sm" (click)="clickHandler(false)"><i class="fas fa-times-circle"></i>
          {{'CANCEL' | translate}}</button>
      </div>
    </div>
  </div>

</div>